const COOKIE_TYPE = {
  ga: '_ga',
  gat: '_gat',
  gid: '_gid',
};

export const cookieConsentBG = 'Използваме бисквитки, за да подобряваме и измерваме вашето преживяване.';
export const cookieConsentEN = 'We uses cookies to measure and improve your experience.';

export const CONSENT_COOKIE = 'consentCookie';
export const GA_COOKIES = [COOKIE_TYPE.ga, COOKIE_TYPE.gat, COOKIE_TYPE.gid];
