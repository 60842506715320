import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const getButtonSizeStyles = (size, mobileSize) => {
  if (isMobile) {
    switch (mobileSize || size) {
      case 'xxs':
        return tw`px-2 py-0.5 text-xs`;
      case 'xs':
        return tw`px-3 py-1.5 text-xs`;
      case 'sm':
        return tw`px-4 py-2 text-sm`;
      case 'lg':
        return tw`px-8 py-4 text-lg`;
      case 'xl':
        return tw`px-10 py-5 text-xl`;
      default:
        return tw`px-4 py-2 text-xs`;
    }
  } else {
    switch (size) {
      case 'xxs':
        return tw`px-2 py-0.5 text-xs`;
      case 'xs':
        return tw`px-3 py-1.5 text-xs`;
      case 'sm':
        return tw`px-4 py-2 text-sm`;
      case 'lg':
        return tw`px-8 py-4 text-lg`;
      case 'xl':
        return tw`px-10 py-5 text-xl`;
      default:
        return tw`px-6 py-3 text-base`;
    }
  }
};

const StyledButtonBase = styled.button`
  ${tw`rounded-2xl
    shadow-raised
    transition duration-300 text-white
    md:hover:shadow-outline md:hover:outline-none`}
  ${({ color }) => [
    color === 'primary' && tw`bg-primary-500 md:hover:bg-primary-700 md:hover:shadow-outline`,
    color === 'secondary' &&
      tw`border border-primary-500 
      text-primary-500
      md:hover:border-primary-700 md:hover:bg-primary-700 md:hover:text-white`,
  ]}

  ${({ size, mobileSize }) => getButtonSizeStyles(size, mobileSize)}
`;

const Container = tw.div`flex flex-row items-center justify-center`;

const StartIcon = tw.span`mr-2`;
const EndIcon = tw.span`ml-2`;

export const ButtonBase = ({
  onClick,
  disabled = false,
  endIcon = null,
  startIcon = null,
  size = 'sm',
  mobileSize = null,
  color,
  type = 'button',
  isFirst = false,
  isLast = false,
  children,
}) => {
  return (
    <StyledButtonBase
      color={color}
      disabled={disabled}
      isFirst={isFirst}
      isLast={isLast}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      mobileSize={mobileSize}
      onClick={onClick}
    >
      <Container>
        {startIcon && <StartIcon>{startIcon}</StartIcon>}
        <span>{children}</span>
        {endIcon && <EndIcon>{endIcon}</EndIcon>}
      </Container>
    </StyledButtonBase>
  );
};
