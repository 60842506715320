import React from 'react';

// TODO: Refactor
export default ({ url, background = '#000000', className = 'video' }) => {
  return (
    <div
      className={className}
      style={{
        position: 'relative',
        background: background,
        height: '100%',
      }}
    >
      <iframe
        title='Embeded Video'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={url}
        frameBorder='0'
      />
    </div>
  );
};
