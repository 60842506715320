import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import PuffLoader from 'react-spinners/PuffLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import HashLoader from 'react-spinners/HashLoader';

const Container = styled.div`
  ${({ fullHeight }) => fullHeight && tw`h-full min-h-2/3`}
  ${({ centered }) => centered && tw`flex flex-col items-center justify-center`}
`;

const Loading = ({
  size = LOADER_SIZE.LARGE,
  variant = LOADER_VARIANTS.HASH,
  color = '#5011CC',
  fullHeight = false,
  centered = false,
  cssOverride = null,
}) => {
  const config = {
    size: calculatedSize(size),
    variant,
    color,
    cssOverride,
  };

  return (
    <Container fullHeight={fullHeight} centered={centered}>
      {getLoader(config)}
    </Container>
  );
};

export default Loading;

const LOADER_VARIANTS = {
  PUFF: 'puff',
  CLIP: 'clip',
  HASH: 'hash',
};

const LOADER_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra-large',
};

const SIZE = {
  SMALL: 24,
  MEDIUM: 36,
  LARGE: 48,
  EXTRA_LARGE: 64,
};

const getLoader = ({ size, variant, color, cssOverride }) => {
  switch (variant) {
    case LOADER_VARIANTS.PUFF:
      return <PuffLoader color={color} size={size} cssOverride={cssOverride} />;
    case LOADER_VARIANTS.CLIP:
      return <ClipLoader color={color} size={size} cssOverride={cssOverride} />;
    case LOADER_VARIANTS.HASH:
      return <HashLoader color={color} size={size} cssOverride={cssOverride} />;
    default:
      return <ClipLoader color={color} size={size} cssOverride={cssOverride} />;
  }
};

const calculatedSize = (size) => {
  switch (size) {
    case LOADER_SIZE.SMALL:
      return SIZE.SMALL;
    case LOADER_SIZE.MEDIUM:
      return SIZE.MEDIUM;
    case LOADER_SIZE.LARGE:
      return SIZE.LARGE;
    case LOADER_SIZE.EXTRA_LARGE:
      return SIZE.EXTRA_LARGE;
    default:
      return size;
  }
};
