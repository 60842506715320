import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings';
import { SectionDescription } from 'components/misc/Typography';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow-right-icon.svg';

const Heading = tw(SectionHeading)`px-6 lg:pt-8 lg:text-2xl 3xl:text-3xl`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-4 sm:mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-6 rounded transition-transform duration-300 cursor-default transform md:hover:scale-105 `}

  .imageContainer {
    ${tw`text-center rounded-full p-2 bg-gray-100 shadow-md`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-md md:text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default ({
  cards = [],
  linkText,
  heading,
  subheading,
  description,
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className='imageContainer' css={imageContainerCss}>
                  <img src={card.imageSrc} alt={card.alt} css={imageCss} />
                </span>
                <span className='title'>{card.title}</span>
                <p className='description'>{card.description}</p>
                {linkText && (
                  <span className='link'>
                    <span>{linkText}</span>
                    <ArrowRightIcon className='icon' />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
