import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import { NavigationProvider, useNavigationContext } from 'components/footers/NavigationProvider';

const ContentFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const MainContent = styled.main`
  flex: 1;
`;

const Layout = ({ children }) => {
  return (
    <NavigationProvider>
      <ContentWithNavigation>{children}</ContentWithNavigation>
    </NavigationProvider>
  );
};

const ContentWithNavigation = (props) => {
  const { isProfileNavOpen } = useNavigationContext();

  return (
    <>
      <Header />
      <ContentFooterWrapper>
        <MainContent>{props.children}</MainContent>
        {!isProfileNavOpen && <Footer />}
      </ContentFooterWrapper>
    </>
  );
};

export default Layout;
