import React from 'react';
import { ButtonBase } from './ButtonBase';

export const ButtonPrimary = ({
  onClick,
  disabled = false,
  endIcon = null,
  startIcon = null,
  size = 'large',
  isFirst = false,
  isLast = false,
  children,
}) => {
  return (
    <ButtonBase
      variant='contained'
      color='primary'
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      isFirst={isFirst}
      isLast={isLast}
    >
      {children}
    </ButtonBase>
  );
};
