export const formErrorMessages = {
  requiredField: '* Моля попълнете!',
  latinLetterOnlyMessage: 'Разрешени са само латински букви',
  equalPasswords: 'Паролите трябва да съвпадат!',
  phoneFormat: 'Невалиден телефон!',
  phoneMinLength: 'Телефонният номер трябва да съдържа поне 8 символа!',
  phoneMaxLength: 'Телефонният номер не трябва да бъде повече от 16 символа!',
  iban: 'Невалиден IBAN',
  ibanMaxLength: 'Невалидна дължина на IBAN',
  ibanChecksum: 'Невалидна контролна сума на IBAN',
  email: 'Невалиден имейл!',
  upperCaseLetter: 'Паролата трябва да съдържа поне една главна буква!',
  lowerCaseLetter: 'Паролата трябва да съдържа поне една малка буква!',
  containsDigit: 'Паролата трябва да съдържа поне една цифра!',
  minPricePerHour: 'Минималната стойност е 100.',
  maxPricePerHour: 'Максималната стойност е 200.',
  invalidNumber: 'Моля, въведете валидно число.',
  invalidURL: 'Моля, въведете валиден URL адрес.',
  titleMaxLength: 'Не трябва да бъде повече от 50 символа!',
  shortDescriptionMaxLength: 'Краткото описание не трябва да бъде повече от 120 символа!',
  longDescriptionMaxLength: 'Дългото описание не трябва да бъде повече от 600 символа!',
  minExperienceYears: 'Минималната стойност е 5 години!',
  maxExperienceYears: 'Максималната стойност е 100 години!',
  cityMaxLength: 'Не трябва да бъде повече от 100 символа!',
  countryMaxLength: 'Не трябва да бъде повече от 100 символа!',
  addressMaxLength: 'Не трябва да бъде повече от 255 символа!',
  postalCodeMaxLength: 'Не трябва да бъде повече от 10 символа!',
  mentorTermsAndConditions: '* Трябва да приемете общите условия за ментори!',
  minChars: (fieldName, chars) => `${fieldName} трябва да бъде най-малко ${chars} символа!`,
  maxChars: (fieldName, chars) => `${fieldName} не трябва да бъде повече от ${chars} символа!`,
};
