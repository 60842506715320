import ReactGA from 'react-ga4';
import { isProduction } from '../environments';

const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const initGA = () => {
  if (isProduction && analyticsId) {
    ReactGA.initialize(analyticsId);
  }
};
