import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import { isBrowser } from 'react-device-detect';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi';
import { SectionHeading as HeadingTitle } from '../misc/Headings';

const Container = tw.div`relative items-center justify-center mb-12 md:mb-0`;
const Content = tw.div`max-w-screen-xl mx-auto xs:pt-8 lg:py-20 md:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.h1`mt-2 text-sm md:text-md px-4 md:px-0 text-gray-600 text-center max-w-sm`;

const ReviewCard = styled.div`
  ${tw`p-4 md:p-8 flex justify-center items-center mt-8 md:mt-2`}
`;

const ReviewFlexContainer = styled.div`
  ${tw`flex flex-col md:flex-row items-center justify-center w-full max-w-208 mx-auto`}
`;

const ReviewTextContainer = styled.div`
  ${(props) => (props.isShortText ? tw`items-start` : tw`items-center`)}
  ${tw`flex flex-row p-4 md:p-6 shadow-lg rounded-xl bg-white bg-opacity-75`}
`;

const ReviewerName = styled.h3`
  ${tw`text-md md:text-lg font-medium text-gray-800`}
`;
const ReviewDate = styled.time`
  ${tw`text-sm text-gray-600 mb-2`}
`;

const ReviewText = styled.p`
  ${tw`italic text-sm md:text-md text-gray-700 relative mt-2`}
`;

const RatingContainer = styled.div`
  ${tw`flex items-center mb-2`}
`;
const QuoteIcon = styled.div`
  ${tw`mt-4 text-primary-500 text-xl`}
`;

const ReviewerImage = styled.img`
  ${tw`w-14 h-14 rounded-full -mr-14 md:-mr-10 mt-2 md:mt-0 mb-auto`}
`;

const StyledYellowStar = styled(FaStar)`
  ${tw`w-5 h-5 text-yellow-400`}
`;

const StyledYellowHalfStar = styled(FaStarHalf)`
  ${tw`w-5 h-5 text-yellow-400`}
`;

const StyledGrayStar = styled(FaStar)`
  ${tw`w-5 h-5 text-gray-400`}
`;

const StarContainer = styled.div`
  ${tw`relative w-5 h-5 inline-block`}
`;

const Column1 = styled.div`
  ${tw`w-1/12 flex items-center justify-center`}
`;

const Column2 = styled.div`
  ${tw`md:w-10/12 flex flex-col items-center md:items-start justify-center`}
`;

const Column3 = styled.div`
  ${tw`w-1/12 flex items-center justify-center`}
`;

const settings = {
  dots: true,
  infinite: true,
  autoplay: isBrowser,
  autoplaySpeed: 15000,
  arrows: false,
  draggable: true,
};

const ClientReviewCarousel = ({ heading, headingDescription }) => {
  const originalReviews = [
    {
      id: 1,
      name: 'Иван Апостолов',
      date: '27 септември 2023г.',
      rating: 5,
      comment:
        'Една изключително добра и информираща среща с Валерия. Отговори на всички въпроси, свързани с дизайн сферата, които имах (и не само) и имах много приятен разговор с нея. Получих повече коректна информация за един час от колкото 4 години следване в университет.',
      imageUrl: '',
    },
    {
      id: 2,
      name: 'Елена Неновска',
      date: '1 август 2023г.',
      rating: 5,
      comment:
        'Доволна съм от срещата с Валерия - изключително мила, отзивчива и позитивна и мисля включително и в бъдеще да поддържам връзка с нея, тъй като е доста опитна в сферата, в която искам и аз да се развивам.',
      imageUrl: '',
    },
    {
      id: 3,
      name: 'Йоан Атанасов',
      date: '1 февруари 2024г.',
      rating: 5,
      comment:
        'В рамките на сесията, успяхме да съставим добър план, за доближаване до дискутираната цел. Миро е изключително компетентен професионалист, който може да предостави свежи, но и прагматични перспективи по даден проблем. Винаги е приятно да разгръщаш идеи с подобни хора :)',
      imageUrl: '',
    },
    {
      id: 4,
      name: 'Светлозара Велчева',
      date: '1 февруари 2024г.',
      rating: 5,
      comment:
        'Валерия е страхотна! Помогна ми с всичко, от което имах нужда. Насоките и обратната връзка, които ми даде, бяха професионални и градивни. Получих разнообразни и тествани решения за предизвикателствата си. Почувствах се страхотно в нейната компания и енергията. Изслушваше ме внимателно и търпеливо!',
      imageUrl: '',
    },
    {
      id: 5,
      name: 'Йордан Петров',
      date: '1 февруари 2024г.',
      rating: 5,
      comment:
        'Мирослав е много дружелюбен и предразполагащ. Веднага вникна в същността на казуса който имах. В рамките на разговора ни успя да анализира силни и слаби страни на проекта, да сподели свой опит, да даде насоки и да предложи възможни решения. Препоръчвам сърдечно.',
      imageUrl: '',
    },
  ];

  // State for shuffled reviews
  const [shuffledReviews, setShuffledReviews] = useState([...originalReviews]);

  const simpleShuffleReviews = (reviews) => {
    return [...reviews].sort(() => Math.random() - 0.5);
  };

  // Shuffle reviews on component mount and each re-render
  useEffect(() => {
    setShuffledReviews(simpleShuffleReviews(originalReviews));
  }, []);

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{headingDescription}</HeadingDescription>
        </HeadingInfoContainer>
        <Slider {...settings}>
          {shuffledReviews.map((review) => {
            const isShortText = review.comment.split(' ').length < 20; // Set your own length threshold here

            return (
              <ReviewCard key={review.id}>
                <ReviewFlexContainer>
                  <ReviewTextContainer isShortText={isShortText}>
                    {review.imageUrl && <ReviewerImage src={review.imageUrl} alt={`${review.name}'s image`} />}
                    {isBrowser && (
                      <Column1>
                        <QuoteIcon>
                          <BiSolidQuoteAltLeft />
                        </QuoteIcon>
                      </Column1>
                    )}
                    <Column2>
                      <ReviewerName>{review.name}</ReviewerName>
                      <ReviewDate dateTime={review.date}>{review.date}</ReviewDate>
                      <RatingContainer>{renderStars(review.rating)}</RatingContainer>
                      <ReviewText>{review.comment}</ReviewText>
                    </Column2>
                    {isBrowser && (
                      <Column3>
                        <QuoteIcon>
                          <BiSolidQuoteAltRight />
                        </QuoteIcon>
                      </Column3>
                    )}
                  </ReviewTextContainer>
                </ReviewFlexContainer>
              </ReviewCard>
            );
          })}
        </Slider>
      </Content>
    </Container>
  );
};

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  let emptyStars = 5 - fullStars;
  if (hasHalfStar) {
    emptyStars--;
  }

  return (
    <>
      {Array.from({ length: fullStars }, (_, index) => (
        <StyledYellowStar key={index} />
      ))}
      {hasHalfStar && (
        <StarContainer>
          <StyledGrayStar />
          <StyledYellowHalfStar style={{ position: 'absolute', top: 0, left: 0 }} />
        </StarContainer>
      )}
      {Array.from({ length: emptyStars }, (_, index) => (
        <StyledGrayStar key={index + fullStars + 1} />
      ))}
    </>
  );
};

export default ClientReviewCarousel;
