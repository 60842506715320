import React from 'react';
import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';

import useInView from 'hooks/useInView';
import LoadingCenteredPage from 'components/spinners/LoadingCenteredPage';

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */

const Container = styled.div(({ mobilePaddingX, noStyles }) => [
  noStyles ? {} : tw`font-display text-secondary-500 overflow-hidden md:pb-8 w-full`,
  !noStyles && mobilePaddingX && `padding-left: ${mobilePaddingX}; padding-right: ${mobilePaddingX};`,
]);

const directions = ['left', 'right'];

const AnimationReveal = ({ disabled, children }) => {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    );
  });
  return <>{childrenWithAnimation}</>;
};

const AnimatedSlideInComponent = ({ direction = 'left', offset = 30, children }) => {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px` });

  const x = { target: '0%' };

  if (direction === 'left') x.initial = '-150%';
  else x.initial = '150%';

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{
          x: inView && x.target,
          transitionEnd: {
            x: inView && 0,
          },
        }}
        transition={{ type: 'spring', damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
};

const AnimationRevealPage = ({
  disabled,
  loading,
  mobilePaddingX = '1rem',
  children,
  noStyles = false,
  fullHeightLoading = true,
}) => {
  return (
    <Container className='App' mobilePaddingX={mobilePaddingX} noStyles={noStyles}>
      {loading ? (
        <LoadingCenteredPage fullHeight={fullHeightLoading} />
      ) : (
        <AnimationReveal disabled={disabled}>{children}</AnimationReveal>
      )}
    </Container>
  );
};

export default AnimationRevealPage;
