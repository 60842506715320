import React, { useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { isBrowser } from 'react-device-detect';

import { openInNewWindow } from 'helpers';
import { useModal } from 'hooks';
import ResponsiveVideoEmbed from '../_shared/ResponsiveVideoEmbed';
import Modal from 'components/modals/VideoModal';
import Button from 'components/buttons';
import BulletCheckMarkList from '../bullets/BulletCheckMarkList';
import DesignIllustration from 'assets/images/design-illustration.svg';
import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'assets/images/dot-pattern.svg';
import { ReactComponent as SvgDecoratorBlob3 } from 'assets/images/svg-decorator-blob-8.svg';
//import { ReactComponent as CloudImage3 } from 'assets/images/cloud-image3.svg';

import Lottie from 'react-lottie';
//import * as sun from 'assets/images/sun.json';
import * as rocket from 'assets/images/rocket.json';
import * as rocket2 from 'assets/images/rocket2.json';

import GradientImage from 'assets/images/custom-gradient.svg';

const Container = styled.div`
  position: relative;
  background-image: url('${(props) => props.imageSrc}');
  ${tw`relative`};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${GradientImage}');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%, 0 80%);
  }

  @media (max-width: 1024px) {
    &::before {
      background-image: none;
    }
  }
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto sm:pb-10 sm:pb-20`;
const LeftColumn = tw.div`lg:mb-32 relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black mt-6 md:mt-0 xs:px-12 sm:px-0 md:pr-16 text-2xl md:text-3xl leading-snug max-w-3xl`;
//const Paragraph = tw.h2`my-5 xs:px-6 lg:px-2 lg:my-8 text-sm lg:text-base text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const VideoEmbed = tw(ResponsiveVideoEmbed)`h-full w-full`;
const IllustrationContainer = tw.div`hidden lg:block mx-auto lg:mb-10 mt-auto mb-auto flex justify-center items-center relative max-w-xs lg:max-w-144`;

const DecoratorBlob1 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none fill-current text-blue-200 absolute xs:hidden 3xl:block w-64 right-0 bottom-0 transform -translate-x-10 4xl:-translate-x-32 -translate-y-104 -z-10`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute xs:w-24 lg:w-32 right-0 bottom-0 transform translate-x-12 translate-y-20 -z-10`}
`;

const DecoratorBlob3 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute left-0 bottom-0 w-32 transform lg:-translate-x-1/2 xs:-translate-x-24 rotate-45 translate-y-96 -z-10`}
`;
// const DecoratorCloud3 = styled(CloudImage3)`
//   ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute snap-center top-0 xs:w-32 lg:w-32 transform lg:translate-x-160 lg:-translate-y-112 xs:translate-x-72 xs:-translate-y-64 -z-10`}
// `;
const HideOnSmallScreen = tw.div`xl:block hidden`;

// const sunOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: sun.default,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };

const rocketOptions = {
  loop: true,
  autoplay: true,
  animationData: rocket.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const rocketOptions2 = {
  loop: true,
  autoplay: true,
  animationData: rocket2.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default ({
  heading = null,
  //description = null,
  watchVideoButtonText = 'Watch Video',
  watchVideoYoutubeUrl = null,
  imageSrc = DesignIllustration,
  imageCss = null,
  features = [],
}) => {
  const { modalIsOpen, toggleModal } = useModal();

  const watchVideoClickHandler = useCallback(
    () => (isBrowser ? toggleModal() : openInNewWindow(watchVideoYoutubeUrl)),
    [toggleModal, watchVideoYoutubeUrl]
  );

  return (
    <>
      <Container>
        <TwoColumn>
          {/* <DecoratorCloud3 /> */}
          {/* <HideOnSmallScreen>
            <Lottie
              options={sunOptions}
              width={180}
              height={160}
              style={{ position: 'absolute', right: 100, top: -5, pointerEvents: 'none' }}
            />
          </HideOnSmallScreen> */}
          <HideOnSmallScreen>
            <Lottie
              options={rocketOptions}
              width={130}
              height={180}
              style={{
                position: 'absolute',
                right: 30,
                bottom: -600,
              }}
            />
          </HideOnSmallScreen>
          <HideOnSmallScreen>
            <Lottie
              options={rocketOptions2}
              width={140}
              height={140}
              style={{
                position: 'absolute',
                left: 70,
                bottom: -1600,
                transform: 'rotate(15deg)',
              }}
            />
          </HideOnSmallScreen>
          <LeftColumn>
            <Heading>{heading}</Heading>
            {/* <Paragraph>{description}</Paragraph> */}
            <BulletCheckMarkList list={features} />
            <Actions>
              {watchVideoButtonText && (
                <Button.Secondary size='sm' mobileSize='xs' onClick={watchVideoClickHandler} startIcon={<PlayIcon />}>
                  {watchVideoButtonText}
                </Button.Secondary>
              )}
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img css={imageCss} src={imageSrc} alt='illustration' />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>

        <DecoratorBlob1 />
        <DecoratorBlob2 />
        <DecoratorBlob3 />
        <Modal isOpen={modalIsOpen} onClose={toggleModal}>
          <VideoEmbed url={watchVideoYoutubeUrl} />
        </Modal>
      </Container>
    </>
  );
};
