import { React, Suspense, useMemo, useCallback, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import jwt_decode from 'jwt-decode';

import GlobalStyles from 'styles/GlobalStyles';
import Layout from 'components/layout/Layout';
import CookieConsent from 'components/cookie/CookieConsent';
import ErrorFallback from 'components/_shared/ErrorFallback';
import Toast from 'components/toast/Toast';
import LoadingCenteredPage from 'components/spinners/LoadingCenteredPage';
import { Routes } from 'config';
import AuthContext from '../context/AuthContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { TOKEN_KEY, cookieConsentBG } from 'helpers';
import { HelmetProvider } from 'react-helmet-async';

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const { getItem, clear } = useLocalStorage();
  const [isLogged, setIsLogged] = useState(getItem(TOKEN_KEY));

  const setIsLoggedOptimized = useCallback((newValue) => setIsLogged(newValue), []);

  useEffect(() => {
    if (isLogged) {
      const decodedToken = jwt_decode(getItem(TOKEN_KEY));
      if (decodedToken.exp < Date.now() / 1000) {
        setIsLoggedOptimized(false);
        clear(TOKEN_KEY);
      }

      //TODO: !!DELETE THIS AFTER 1ST MARCH 2024!!
      const cutoffDate = 1706545650; //new Date('2024-01-29T20:25:00').getTime() / 1000; // Дата на смяна на ключа в Unix timestamp
      if (decodedToken.iat < cutoffDate) {
        setIsLoggedOptimized(false);
        clear(TOKEN_KEY);
      }
    }
  }, [getItem, setIsLoggedOptimized, clear, isLogged]);

  const contextValue = useMemo(
    () => ({
      isLogged,
      setIsLoggedOptimized,
    }),
    [isLogged, setIsLoggedOptimized]
  );

  return (
    <HelmetProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthContext.Provider value={contextValue}>
          <>
            <GlobalStyles />
            <Toast />
            <Router>
              <Suspense fallback={<LoadingCenteredPage />}>
                <Layout>
                  <Routes />
                </Layout>
              </Suspense>
            </Router>
            <CookieConsent text={cookieConsentBG} />
          </>
        </AuthContext.Provider>
      </ErrorBoundary>
    </HelmetProvider>
  );
}
