export const useLocalStorage = () => {
  const localStorage = window.localStorage;

  function getItem(key) {
    return localStorage.getItem(key);
  }

  function setItem(key, value) {
    return localStorage.setItem(key, value);
  }

  function removeAllItems() {
    Object.keys(localStorage).forEach((key) => localStorage.removeAllItems(key));
  }

  function key(index) {
    localStorage.key[index];
  }

  function length() {
    localStorage.length;
  }

  function clear(key) {
    localStorage.clear(key);
  }

  return { getItem, setItem, removeAllItems, key, length, clear };
};
