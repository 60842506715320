import React from 'react';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { pages } from 'config/paths';
import { Helmet } from 'react-helmet-async';

import AnimationRevealPage from 'components/_shared/AnimationRevealPage';
import IntroWithVideo from 'components/hero/TwoColumnWithVideo';
import Stats from 'components/features/TwoColSingleFeatureWithStats';
import VisionSection from 'components/features/ThreeColSimple';
import Faqs from 'components/faqs/SingleCol';
import GetStarted from 'components/cta/GetStartedLight';
import TopMentors from 'components/carousels/TwoColumnImageCarousel';
import LogoCarousel from 'components/carousels/LogoCarousel';

import GlobeImage from 'assets/images/globe.gif';
import LaptopImage from 'assets/images/swipe.gif';
import ClockImage from 'assets/images/clock.gif';
import ConsultationsImage from 'assets/images/consultations.gif';
import SupportImage from 'assets/images/support.gif';
import PrivacyImage from 'assets/images/privacy.gif';

import TeachCodingIllustration from 'assets/images/home-page-banner.png';

import ScrollToTopButton from 'components/form-controls/ScrollToTopButton';
import Separator from 'components/form-controls/Separator';
import ClientReviewCarousel from 'components/carousels/ClientReviewCarousel';

// TODO: Refactor: move it inside a separate component and reuse it
const HighlightedText = tw.span`text-primary-600`;

const IntroHeading = (
  <span>
    Експерти за всеки <HighlightedText>бизнес и технологичен</HighlightedText> казус
  </span>
);

const HomePage = () => {
  const navigate = useNavigate();

  const redirectToMentors = (id) => {
    const url = `${pages.portal.mentors.publicProfileLink}/${id}`;
    return navigate(url);
  };

  return (
    <>
      <Helmet>
        <title>Mentorsko | Индивидуални консултации с експерти</title>
        <meta
          name='description'
          content='Експерти за всеки бизнес и технологичен казус. Учете от най-добрите в Mentorsko.'
        />
        <link rel='canonical' href='https://mentorsko.com' />
        <meta property='og:title' content='Mentorsko | Индивидуални консултации с експерти' />
        <meta property='og:description' content='Експерти за всеки бизнес и технологичен казус.' />
        <meta property='og:url' content='https://mentorsko.com' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://mentorsko.com/static/media/logo.80eeff0232e314ce68a2d46eb6d06458.svg'
        />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content='Mentorsko | Индивидуални консултации с експерти' />
        <meta name='twitter:description' content='Експерти за всеки бизнес и технологичен казус.' />
        <meta
          name='twitter:image'
          content='https://mentorsko.com/static/media/logo.80eeff0232e314ce68a2d46eb6d06458.svg'
        />
        <script type='application/ld+json'>
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://mentorsko.com",
            "logo": "https://mentorsko.com/static/media/logo.80eeff0232e314ce68a2d46eb6d06458.svg",
            "contactPoint": [
              {
                "@type": "ContactPoint",
                "email": "support@mentorsko.com",
                "contactType": "customer support",
                "availableLanguage": ["Bulgarian", "English"]
              }
            ],
            "sameAs": [
              "https://www.facebook.com/mentorskocom",
              "https://www.linkedin.com/company/mentorsko",
              "https://www.instagram.com/mentorskocom"
            ]
          }`}
        </script>
        <script type='application/ld+json'>
          {`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Mentorsko | Индивидуални консултации с експерти",
              "description": "Консултирай се с опитни експерти с дългогодишен практически опит и разгърни своя потенциал с индивидуални консултации.",
              "url": "https://mentorsko.com",
              "inLanguage": "bg",
              "publisher": {
                "@type": "Organization",
                "name": "Mentorsko"
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Начало",
                    "item": "https://mentorsko.com/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Ментори",
                    "item": "https://mentorsko.com/mentors/all"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Стани Ментор",
                    "item": "https://mentorsko.com/become-mentor"
                  }
                ]
              },
              "mainEntity": {
                "@type": "ItemList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Начало",
                    "item": {
                      "@id": "https://mentorsko.com/",
                      "name": "Начална страница"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Ментори",
                    "item": {
                      "@id": "https://mentorsko.com/mentors/all",
                      "name": "Намери Ментор"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Стани Ментор",
                    "item": {
                      "@id": "https://mentorsko.com/become-mentor",
                      "name": "Присъедини се като Ментор"
                    }
                  }
                ]
              }
            }`}
        </script>
      </Helmet>
      <AnimationRevealPage disabled mobilePaddingX={0}>
        <IntroWithVideo
          heading={IntroHeading}
          description='Консултирай се с опитни ментори с дългогодишен практически опит'
          watchVideoButtonText='За Менторско'
          watchVideoYoutubeUrl='https://www.youtube.com/embed/gBpupUY8pbQ?si=QR9KvFhWDqcutKAm&modestbranding=1'
          imageSrc={TeachCodingIllustration}
          imageDecoratorBlob={false}
          features={checks}
        />
        <LogoCarousel
          heading={
            <span>
              Експерти от <HighlightedText>водещи </HighlightedText>компании
            </span>
          }
        />
        <Separator mobileOnly={true} />
        <TopMentors
          heading={
            <span>
              Популярни <HighlightedText>ментори</HighlightedText>
            </span>
          }
          headingDescription='Разчитай на доказали се експертите с дългогодишен опит'
          cardClickHandler={redirectToMentors}
        />
        <Stats statisticsData={statisticsData} description={trackRecordDescription} />
        <VisionSection
          heading={
            <span>
              Предимства на <HighlightedText>Менторско</HighlightedText>
            </span>
          }
          description=''
          cards={platformBenefitsCards}
          linkText=''
        />
        <ClientReviewCarousel
          heading={
            <span>
              Отзиви от <HighlightedText>клиенти</HighlightedText>
            </span>
          }
          headingDescription='Открийте защо нашите клиенти избират Менторско.'
        />
        <Faqs
          heading={
            <span>
              Често задавани <HighlightedText>въпроси</HighlightedText>
            </span>
          }
          faqs={faqs}
        />

        <GetStarted
          subheading='Разгледай всички ментори.'
          heading='Присъедини се като ментор!'
          primaryLinkText='Ментори'
          primaryLinkUrl={pages.portal.mentors.all}
          secondaryLinkText='Стани ментор'
          secondaryLinkUrl={pages.becomeMentor}
        />
        <ScrollToTopButton />
      </AnimationRevealPage>
    </>
  );
};

export default HomePage;

const checks = ['1:1 консултации', 'Expert on Demand', 'Персонализирани корпоративни обучения'];

const statisticsData = [
  { value: 40, suffix: '+', title: 'Ментора' },
  { value: 13, suffix: '', title: 'Години средно опит' },
  { value: 100, suffix: '+', title: 'Доволни клиенти' },
  { value: 4.9, suffix: '/5', title: 'Средна оценка' },
];

const trackRecordDescription =
  'Нашата платформа е нова, но ние се развиваме бързо. Тук можете да видите интересна статистика от обученията ни.';

const platformBenefitsCards = [
  {
    imageSrc: GlobeImage,
    title: 'Експертиза',
    description: 'Ментори с дългогодишен опит в индустрията и богата експертиза, преминали стриктен подбор.',
    alt: 'Експертиза икона',
  },
  {
    imageSrc: LaptopImage,
    title: 'Удобство',
    description: 'Открийте лесно най-добрия ментор за вашите цели и започнете удобен процес на обучение.',
    alt: 'Удобство',
  },
  {
    imageSrc: ClockImage,
    title: 'Гъвкавост',
    description:
      'Планирайте сесии във времето, което работи най-добре за вас, съчетавайки менторството в натоварения си график.',
    alt: 'Гъвкавост',
  },
  {
    imageSrc: ConsultationsImage,
    title: 'Разнообразие',
    description: 'Ментори с опит в широк спектър от области за вашите специфични нужди и цели.',
    alt: 'Разнообразие',
  },
  {
    imageSrc: PrivacyImage,
    title: 'Конфиденциалност',
    description:
      'Менторските сесии са поверителни, за да гарантираме комфорта да обсъждате вашите цели и предизвикателства.',
    alt: 'Конфиденциалност',
  },
  {
    imageSrc: SupportImage,
    title: 'Съдействие',
    description: 'Нашият отговорен и отзивчив екип е винаги на разположение за бързо съдействие по вашите въпроси.',
    alt: 'Съдействие',
  },
];

const faqs = [
  {
    question: 'Какво представлява Mentorsko.com?',
    answer:
      'Платформа, която свързва топ IT и бизнес професионалисти с дългогодишен опит в сферата с хората, които искат да се учат от тях.',
  },
  {
    question: 'Как става консултирането?',
    answer: 'Клиентите на платформата могат да изберат ментор и да го наемат на час за индивидуална консултация.',
  },
  {
    question: 'Как преподават менторите?',
    answer:
      'Всеки ментор има свой график, запълнен с консултации. Можете да си запишете консултация с него в някой от свободните му часове.',
  },
  {
    question: 'Как мога да наема ментор?',
    answer:
      'Изберете от страницата на ментора "Запази консултация", въведете дата, час, тема и описание на казуса, който ще обсъждате. След успешно плащане ще получите имейл с линк към срещата и линк към google calendar евент, в който сте заедно с избрания ментор. Също така можете да видите запазената консултация в профила си.',
  },
  {
    question: 'Как мога да стана ментор?',
    answer: 'През бутона "Стани ментор" можете да попълните регистрационна форма за ментори.',
  },
];
