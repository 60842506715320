import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

import Logo from 'assets/images/logo.svg';
import { pages } from 'config';
import { LogoLink } from 'components/headers/Light';
import SocialLinks from './components/SocialLinks';
import ThreeColumnLinks from './components/ThreeColumnLinks';
import VisaImage from 'assets/images/visa-icon.svg';
import MasterCardImage from 'assets/images/mastercard-icon.svg';
import GooglePayImage from 'assets/images/google-pay-icon.svg';

const Container = tw.div`relative bg-gray-100 text-gray-700 px-8 py-8`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const Divider = tw.div`my-8 border-b border-gray-300 w-full`;
const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const CopyrightText = tw.p`mt-2 md:mt-0 font-medium text-sm text-secondary-500`;

const PaymentsContainer = tw.div`xs:mt-3 flex justify-center items-center`;

const IconContainer = styled.div`
  ${tw`flex items-center`}
  margin-left: 0.1rem;
  margin-right: 0.1rem;
`;

const Icon = tw.img`h-10`;

const VisaIcon = () => (
  <IconContainer>
    <Icon src={VisaImage} alt='Visa' />
  </IconContainer>
);

const MastercardIcon = () => (
  <IconContainer>
    <Icon src={MasterCardImage} alt='Mastercard' />
  </IconContainer>
);

const GooglePayIcon = () => (
  <IconContainer>
    <Icon src={GooglePayImage} alt='Google Pay' />
  </IconContainer>
);

const ThreeColumnFooter = ({ companyDescription, companyEmail, quickLinks, productLinks, legalLinks }) => {
  const footerColumns = [
    { list: quickLinks.list, header: quickLinks.header, hasElements: quickLinks.list.length > 0 },
    { list: legalLinks.list, header: legalLinks.header, hasElements: legalLinks.list.length > 0 },
    { list: productLinks.list, header: productLinks.header, hasElements: productLinks.list.length > 0 },
  ];

  return (
    <Container>
      <Content>
        <ThreeColumnLinks companyDescription={companyDescription} columns={footerColumns} companyEmail={companyEmail} />
        <Divider />
        <ThreeColRow>
          <LogoLink href={pages.portal.home} imgWidth={8}>
            <img src={Logo} alt='logo' />
          </LogoLink>
          <CopyrightText>Copyright © 2023. All rights reserved.</CopyrightText>
          <PaymentsContainer>
            <VisaIcon />
            <MastercardIcon />
            <GooglePayIcon />
          </PaymentsContainer>
          <SocialLinks />
        </ThreeColRow>
      </Content>
    </Container>
  );
};

export default ThreeColumnFooter;
