import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { keyframes } from 'styled-components';
import ArrowUpIcon from 'assets/images/arrow-up-icon.svg';

const swipeIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const ArrowUpIconStyled = tw.img`
  w-6 h-6 transition duration-300 ease-in-out
`;

const ScrollToTopButtonContainer = styled.button`
  ${tw`fixed font-bold py-2 px-3 rounded-full z-50 transition duration-300 ease-in-out bg-white bg-opacity-85`}
  animation: ${swipeIn} 0.5s forwards;

  ${ArrowUpIconStyled}:hover {
    transform: scale(1.1);
  }
`;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.matchMedia('(min-width: 640px)').matches) {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    isVisible && (
      <ScrollToTopButtonContainer
        onClick={scrollToTop}
        style={{ bottom: '2rem', right: '1rem', opacity: isVisible ? 1 : 0 }}
      >
        <div tw='flex items-center justify-center'>
          <ArrowUpIconStyled src={ArrowUpIcon} alt='Scroll to top' />
        </div>
      </ScrollToTopButtonContainer>
    )
  );
};

export default ScrollToTopButton;
