export const paths = {
  // Default
  noMatch: '*',
  root: '/',
  error: '/error',
  notFoundError: '/not-found',
  noPermissions: '/no-permissions',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  login: '/login',
  logout: '/logout',
  signup: '/signup',
  email: {
    confirm: '/email/confirm',
  },
  thankYou: '/thank-you',
  components: '/components',
  aboutUs: '/about-us',
  becomeMentor: '/become-mentor',
  contactUs: '/contact-us',
  pricing: '/pricing',
  termsOfService: '/terms',
  termsOfUseMentor: '/terms/mentor',
  private: '/private',
  // Ids
  withId: '/:id',
  // Custom paths
  portal: {
    gdpr: '/gdpr',
    terms: '/terms',
    termsMentor: '/terms/mentor',
    mentors: {
      root: '/mentors',
      all: '/all',
      profile: '/me',
    },
    trainings: {
      root: '/trainings',
      all: '/all',
      corporate: '/corporate',
      details: '/details',
    },
    user: {
      root: '/user',
      profile: '/profile',
    },
    review: {
      root: '/review',
      consultation: '/consultation',
    },
  },
  admin: {
    root: '/admin',
  },
  payment: {
    root: '/payment',
    successful: '/successful',
  },
};

const { portal, admin, payment } = paths;

export const pages = {
  noMatch: `${paths.noMatch}`,
  private: `${paths.private}`,
  error: {
    generic: `${paths.error}`,
    notFound: `${paths.notFoundError}`,
  },
  noPermissions: paths.noPermissions,
  forgotPassword: paths.forgotPassword,
  resetPassword: paths.resetPassword,
  login: paths.login,
  logout: paths.logout,
  signup: paths.signup,
  email: {
    confirm: `${paths.email.confirm}`,
  },
  thankYou: paths.thankYou,
  aboutUs: paths.aboutUs,
  becomeMentor: paths.becomeMentor,
  contactUs: paths.contactUs,
  pricing: paths.pricing,
  portal: {
    home: `${paths.root}`,
    gdpr: `${portal.gdpr}`,
    termsOfService: `${portal.terms}`,
    termsOfUseMentor: `${portal.termsMentor}`,
    mentors: {
      root: `${portal.mentors.root}`,
      all: `${portal.mentors.root}${portal.mentors.all}`,
      profile: `${portal.mentors.root}${portal.mentors.profile}${paths.withId}`,
      profileLink: `${portal.mentors.root}${portal.mentors.profile}`,
      publicProfile: `${portal.mentors.root}${paths.withId}`,
      publicProfileLink: `${portal.mentors.root}`,
    },
    trainings: {
      root: `${portal.trainings.root}`,
      all: `${portal.trainings.root}${portal.trainings.all}`,
      details: `${portal.trainings.root}${portal.trainings.details}${paths.withId}`,
      detailsLink: `${portal.trainings.root}${portal.trainings.details}`,
      corporate: {
        root: `${portal.trainings.root}${portal.trainings.corporate}`,
        all: `${portal.trainings.root}${portal.trainings.corporate}${portal.trainings.all}`,
      },
    },
    user: {
      root: `${portal.user.root}`,
      profile: `${portal.user.root}${portal.user.profile}${paths.withId}`,
      profileLink: `${portal.user.root}${portal.user.profile}`,
    },
    review: {
      root: `${portal.review.root}`,
      consultation: `${portal.review.root}${portal.review.consultation}`,
    },
  },
  admin: {
    root: `${admin.root}`,
  },
  payment: {
    root: `${payment.root}`,
    successful: `${payment.root}${payment.successful}`,
  },
};
