import React from 'react';
import tw from 'twin.macro';

import Column from './Column';

const Container = tw.div`max-w-screen-xl mx-auto flex flex-wrap justify-between`;

const ThreeColumnLinks = ({ columns }) => {
  return (
    <Container>
      {columns.length &&
        columns.map((col, key) => col.hasElements && <Column key={key} header={col.header} links={col.list} />)}
    </Container>
  );
};

export default ThreeColumnLinks;
