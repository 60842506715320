import React from 'react';
import tw from 'twin.macro';

import BulletCheckMark from './BulletCheckMark';

const FeatureList = tw.ul`xs:py-4 xs:pb-6 sm:p-0 mt-8 leading-loose xs:text-sm sm:text-md text-left`;

const Container = tw.div`xs:flex lg:block xs:justify-center xs:items-center`;

const BulletCheckMarkList = ({ list }) => {
  return (
    <Container>
      <FeatureList>
        {list.map((text, index) => (
          <BulletCheckMark key={`checkmark-${index}`} text={text} />
        ))}
      </FeatureList>
    </Container>
  );
};

export default BulletCheckMarkList;
