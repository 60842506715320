export const apiConfig = {
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL,
    testBaseUrl: process.env.REACT_APP_TEST_URL,
    url: process.env.REACT_APP_API_URL,
    aiUrl: process.env.REACT_APP_AI_API_URL,
  },
};

export const apiPaths = {
  // Fake api path
  test: 'posts',
  // Api paths
  emails: 'emails',
  contactUs: 'contact-us',
  login: 'login',
  mentor: 'mentor',
  mentors: 'mentors',
  businessType: 'businessType',
  top: 'top',
  by: 'by',
  auth: 'auth',
  google: 'google',
  register: 'register',
  emailsConfirmation: 'register/user/confirm',
  user: 'user',
  users: 'users',
  client: 'client',
  corporate: 'corporate',
  update: 'update',
  consultation: 'consultation',
  consultations: 'consultations',
  availability: 'availability',
  profile: 'profile',
  skills: 'skills',
  forgotPassword: 'password/forgot',
  resetPassword: 'password/reset',
  publicInfo: 'publicInfo',
  personalInfo: 'personalInfo',
  schedule: `schedule`,
  review: 'review',
  question: 'question',
  questions: 'questions',
  transactions: 'transactions',
  status: 'status',
  experience: 'experience',
  work: 'work',
  education: 'education',
  companies: 'companies',
  partialName: 'partialName',
  take: 'take',
  search: 'search',
  analytics: 'analytics',
  select: 'select',
  visit: 'visit',
  stripe: 'stripe',
  onboardingLink: 'onboarding-link',
  payoutStatus: 'payout-status',
  reschedule: 'reschedule',
  rejectReschedule: 'reject-reschedule',
  acceptReschedule: 'accept-reschedule',
  active: 'active',
  past: 'past',
};

export const httpMethods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};

export const contentTypes = {
  json: 'application/json',
};

export const httpHeaders = {
  contentType: 'Content-Type',
};

export const searchParams = {
  numberOfTopMentors: 'numberOfTopMentors',
};

export const apiTags = {
  mentors: 'mentors',
  login: 'login',
  startLearningSession: 'startLearningSession',
};
