import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

import FacebookIcon from 'assets/images/facebook.png';
import LinkedInIcon from 'assets/images/linkedin.png';
import YoutubeIcon from 'assets/images/youtube.png';
import InstagramIcon from 'assets/images/instagram.png';

const Container = tw.div`mt-4 md:mt-0 flex justify-center`;

const SocialLink = styled.a`
  ${tw`cursor-pointer`}
  &:not(:last-child) {
    ${tw`mr-4`}
  }
`;

const IconImg = styled.img`
  ${tw`w-6 h-6`}
  &:hover {
    filter: brightness(0.7);
  }
`;

const links = [
  { href: 'https://www.facebook.com/mentorskocom', icon: FacebookIcon },
  { href: 'https://www.instagram.com/mentorskocom', icon: InstagramIcon },
  { href: 'https://www.youtube.com/@mentorskocom', icon: YoutubeIcon },
  { href: 'https://www.linkedin.com/company/mentorsko', icon: LinkedInIcon },
];

const SocialLinks = () => {
  return (
    <Container>
      {links.length > 0 &&
        links.map(({ href, icon }, idx) => (
          <SocialLink key={`social-link-${idx}`} target='_blank' href={href}>
            <IconImg src={icon} alt='' />
          </SocialLink>
        ))}
    </Container>
  );
};

export default SocialLinks;
