import React, { lazy } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { pages } from 'config';

import HomePage from 'features/home/HomePage';
//import PrivateRoutes from './PrivateRoutes';

// All main pages
const AboutUsPage = lazy(() => import('features/generic/AboutUsPage'));
const BecomeMentorPage = lazy(() => import('features/generic/BecomeMentorPage'));
const ContactUsPage = lazy(() => import('features/contact-us/ContactUsPage'));
const LoginPage = lazy(() => import('features/login/LoginPage'));
const SignupPage = lazy(() => import('features/signup/SignupPage'));
const ForgotPasswordPage = lazy(() => import('features/forgotPassword/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('features/resetPassword/ResetPasswordPage'));
const EmailConfirmationPage = lazy(() => import('features/generic/EmailConfirmationPage'));
const TermsOfServicePage = lazy(() => import('features/generic/TermsOfServicePage'));
const MentorTermsOfUsePage = lazy(() => import('features/generic/MentorTermsOfUsePage'));

const ProfilePage = lazy(() => import('features/profiles/ProfilePage'));
const PrivacyPolicyPage = lazy(() => import('features/generic/PrivacyPolicyPage'));

// Generic pages
const ErrorPage = lazy(() => import('features/generic/ErrorPage'));
const NotFoundPage = lazy(() => import('features/generic/NotFoundPage'));
const NoPermissionsPage = lazy(() => import('features/generic/NoPermissionsPage'));

// Mentor pages
const MentorsPage = lazy(() => import('features/mentor/MentorsPage'));
const MentorProfilePage = lazy(() => import('features/mentors/profile/MentorProfilePage'));
const MentorsAllPage = lazy(() => import('features/mentor/MentorsAllPage'));
const MentorPublicProfilePage = lazy(() => import('features/mentor/MentorPublicProfilePage'));

// Training pages
const TrainingsPage = lazy(() => import('features/trainings/TrainingsPage'));
const CorporateTrainingsPage = lazy(() => import('features/trainings/corporate/CorporateTrainingsPage'));
const TrainingDetailsPage = lazy(() => import('features/trainings/details/TrainingDetailsPage'));

// Admin pages
const AdminPage = lazy(() => import('features/admin/AdminPage'));

const SuccessfulPaymentPage = lazy(() => import('features/generic/SuccessfulPaymentPage'));
const ReviewConsultationPage = lazy(() => import('features/review/ReviewConsultationPage'));

export const Routes = () => {
  return (
    <RouterRoutes>
      {/* Private pages */}
      {/* <Route element={<PrivateRoutes />}>
        <Route path={pages.private} element={<TestPage />} />
      </Route> */}
      {/* Public pages */}
      <Route path={pages.error.generic} element={<ErrorPage />} />
      <Route path={pages.error.notFound} element={<NotFoundPage />} />
      <Route path={pages.noPermissions} element={<NoPermissionsPage />} />
      <Route path={pages.aboutUs} element={<AboutUsPage />} />
      <Route path={pages.becomeMentor} element={<BecomeMentorPage />} />
      <Route path={pages.contactUs} element={<ContactUsPage />} />
      <Route path={pages.login} element={<LoginPage />} />
      <Route path={pages.signup} element={<SignupPage />} />
      <Route path={pages.forgotPassword} element={<ForgotPasswordPage />} />
      <Route path={pages.resetPassword} element={<ResetPasswordPage />} />
      <Route path={pages.email.confirm} element={<EmailConfirmationPage />} />
      <Route path={pages.portal.termsOfService} element={<TermsOfServicePage />} />
      <Route path={pages.portal.termsOfUseMentor} element={<MentorTermsOfUsePage />} />
      <Route path={pages.admin.root} element={<AdminPage />} />
      <Route path={pages.portal.home} element={<HomePage />} />
      <Route path={pages.portal.gdpr} element={<PrivacyPolicyPage />} />
      {/* Mentors */}
      <Route path={pages.portal.mentors.root} element={<MentorsPage />}>
        <Route path={pages.portal.mentors.profile} element={<MentorProfilePage />} />
        <Route path={pages.portal.mentors.publicProfile} element={<MentorPublicProfilePage />} />
        <Route path={pages.portal.mentors.all} element={<MentorsAllPage />} />
      </Route>

      <Route path={pages.portal.user.profile} element={<ProfilePage />} />

      {/* Trainings */}
      <Route path={pages.portal.trainings.root} element={<TrainingsPage />}>
        <Route path={pages.portal.trainings.corporate.all} element={<CorporateTrainingsPage />} />
        <Route path={pages.portal.trainings.details} element={<TrainingDetailsPage />} />
      </Route>
      {/* No Match */}
      <Route path={pages.noMatch} element={<Navigate to={pages.error.notFound} replace />} />
      {/* Payments */}
      <Route path={pages.payment.successful} element={<SuccessfulPaymentPage />} />

      <Route path={pages.portal.review.consultation} element={<ReviewConsultationPage />} />
    </RouterRoutes>
  );
};
