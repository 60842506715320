import React from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SectionHeading } from 'components/misc/Headings';
import VMwareLogo from 'assets/images/logos/vmware.svg';
import ToptalLogo from 'assets/images/logos/toptal.avif';
import SmuleLogo from 'assets/images/logos/smule.jpg';
import Trading212Logo from 'assets/images/logos/trading212.jpg';
import BroadcomLogo from 'assets/images/logos/broadcom.png';
import HoneywellLogo from 'assets/images/logos/honeywell.png';
//import SoftwareGroupLogo from 'assets/images/logos/softwaregroup.png';
import ExperianLogo from 'assets/images/logos/experian.png';
//import PaddyPowerLogo from 'assets/images/logos/paddypower.png';
//import HpLogo from 'assets/images/logos/hp.jpg';
import DXCLogo from 'assets/images/logos/dxctechnology.png';
import InsightLogo from 'assets/images/logos/insight.png';
import SiemensEnergyLogo from 'assets/images/logos/siemens_energy.png';
import SimCorpLogo from 'assets/images/logos/simcorp.jpg';
import Congnizant from 'assets/images/logos/cognizant.png';
import FlutterentErtainmentLogo from 'assets/images/logos/flutterentertainment.png';
import UniversityOfCambridge from 'assets/images/logos/university-of-cambridge.png';
import PayhawkLgo from 'assets/images/logos/payhawk.png';
import AwsLogo from 'assets/images/logos/aws.png';
import CryptoComLogo from 'assets/images/logos/crypto-com.png';
import IBM from 'assets/images/logos/IBM.svg';
import Microsoft from 'assets/images/logos/microsoft.svg';
import Amazon from 'assets/images/logos/amazon.png';
import SAP from 'assets/images/logos/SAP.png';
import Accentures from 'assets/images/logos/accenture.png';
import Paysafe from 'assets/images/logos/paysafe.svg';

const Content = tw.div`max-w-full mx-auto xs:pt-10 lg:pt-10 xs:pb-10 lg:pb-20 lg:py-24`;
const LogoContainer = tw.div`flex justify-center items-center w-full`;
const Logo = tw.img`h-8 md:h-7 mx-auto pointer-events-none`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingTitle = tw(SectionHeading)`mb-16 px-8 md:px-0`;

const logos = [
  { logo: Paysafe, company: 'Paysafe' },
  { logo: ToptalLogo, company: 'Toptal' },
  { logo: Amazon, company: 'Amazon' },
  { logo: Microsoft, company: 'Microsoft' },
  { logo: IBM, company: 'IBM' },
  { logo: SAP, company: 'SAP' },
  { logo: PayhawkLgo, company: 'Payhawk' },
  { logo: CryptoComLogo, company: 'Crypto.com' },
  { logo: UniversityOfCambridge, company: 'University of Cambridge' },
  { logo: SiemensEnergyLogo, company: 'Siemens Energy' },
  { logo: VMwareLogo, company: 'VMware' },
  { logo: DXCLogo, company: 'DXC Technology' },
  { logo: Accentures, company: 'Accentures' },
  { logo: AwsLogo, company: 'Amazon AWS' },
  { logo: HoneywellLogo, company: 'Honeywell' },
  { logo: ExperianLogo, company: 'Experian' },
  { logo: BroadcomLogo, company: 'Broadcom' },
  { logo: SmuleLogo, company: 'Smule' },
  { logo: Trading212Logo, company: 'Trading 212' },
  { logo: SimCorpLogo, company: 'SimCorp' },
  { logo: InsightLogo, company: 'Insight' },
  { logo: Congnizant, company: 'Cognizant' },
  { logo: FlutterentErtainmentLogo, company: 'Flutter Entertainment' },
];

// Slick settings for the slider
const settings = {
  infinite: true,
  speed: 7000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToScroll: 1,
  slidesToShow: 8,
  autoplay: true,
  pauseOnHover: false,
  draggable: false,
  swipe: false,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: 'ease',
      },
    },
  ],
};

const ClientLogoCarousel = ({ heading }) => {
  return (
    <Content>
      <HeadingInfoContainer>
        <HeadingTitle>{heading}</HeadingTitle>
      </HeadingInfoContainer>
      <Slider {...settings}>
        {logos.map((item, index) => (
          <LogoContainer key={index}>
            <Logo src={item.logo} alt={item.company} />
          </LogoContainer>
        ))}
      </Slider>
    </Content>
  );
};

export default ClientLogoCarousel;
