import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { isMobile } from 'react-device-detect';

import logo from 'assets/images/logo.svg';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';

const Header = tw.header`
  xs:pt-4 xs:pb-2 xs:px-6 lg:p-8 xl:px-0
  font-display
  text-secondary-500
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  my-1 md:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  mt-3 md:mt-0
  lg:mx-0
  px-6 py-3 rounded-2xl bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  cursor-pointer
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl ml-0`};

  img {
    width: ${(props) => (props.imgWidth ? `${props.imgWidth}rem` : isMobile ? '8rem' : '9rem')};
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-100 focus:outline-none hocus:text-primary-500
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-100 absolute top-0 inset-x-0 mx-4 my-4 p-4 border text-center rounded-xl text-gray-900 bg-white overflow-y-auto`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;
const scrollThreshold = 50;

export default ({ logoLink, links, className, collapseBreakpointClass = 'lg' }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const toggleNavbar = () => setIsNavVisible(!isNavVisible);

  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href='/'>
      <img src={logo} alt='logo' />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;

    if (Math.abs(prevScrollPos - currentScrollPos) > scrollThreshold && isNavVisible) {
      toggleNavbar();
      document.activeElement.blur(); // Remove focus from the active element
    }

    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos, isNavVisible, toggleNavbar]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Header className={className || 'header-light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}

        {isNavVisible && <MobileNavLinks css={collapseBreakpointCss.mobileNavLinks}>{links}</MobileNavLinks>}

        <NavToggle onClick={toggleNavbar} className={isNavVisible ? 'open' : 'closed'}>
          {isNavVisible ? <CloseIcon /> : <MenuIcon />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
