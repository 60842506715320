import React from 'react';
import tw from 'twin.macro';

const ColumnHeading = tw.h5`font-bold`;
const Column = tw.div`w-72 px-8 md:px-0 my-4`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

export default ({ header, links }) => {
  return (
    <Column>
      <ColumnHeading>{header}</ColumnHeading>
      <LinkList>
        {links.map(({ href, title }, key) => (
          <LinkListItem key={key}>
            <Link href={href}>{title}</Link>
          </LinkListItem>
        ))}
      </LinkList>
    </Column>
  );
};
