import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings';
import { ContentWithVerticalPadding } from 'components/misc/Layouts';
import { ReactComponent as SvgDecoratorBlob1 } from 'assets/images/svg-decorator-blob-9.svg';

const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Container = tw.section`relative xs:py-10 py-20 md:py-24 bg-primary-900 text-gray-100 md:rounded-none`;
const Content = tw.div`max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:gap-12 xl:gap-16`;
const Statistic = tw.div`transition duration-300 ease-in-out hover:scale-110 cursor-default`;
const StatisticValue = tw.span`text-3xl md:text-4xl 3xl:text-5xl font-bold text-gray-100`;
const StatisticTitle = tw.div`mt-2 mb-4 text-md lg:text-lg font-medium`;
const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;

const StatisticWrapper = styled.div(({ isVisible }) => [
  tw`flex flex-col items-center text-center opacity-0 transition-opacity duration-2000`,
  isVisible && tw`opacity-100`,
]);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-56 translate-y-32 text-primary-500 opacity-50`;

const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 text-primary-500 opacity-50`;

const Statistics = ({ heading = '', subheading = '', statisticsData = [] }) => (
  <ContentWithVerticalPadding>
    <Container>
      {subheading && <Subheading>{subheading}</Subheading>}
      {heading && <SectionHeading>{heading}</SectionHeading>}
      <Content>
        {statisticsData.map((statistic, index) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true,
          });

          return (
            <StatisticWrapper key={index} ref={ref} isVisible={inView}>
              <Statistic>
                <StatisticValue>
                  <CountUp
                    start={inView ? 0 : statistic.value}
                    end={statistic.value}
                    duration={3}
                    decimals={statistic.value % 1 === 0 ? 0 : 1}
                  />
                </StatisticValue>
                <StatisticValue>{statistic.suffix}</StatisticValue>
                <StatisticTitle>{statistic.title}</StatisticTitle>
              </Statistic>

              <DecoratorBlobContainer>
                <DecoratorBlob1 />
                <DecoratorBlob2 />
              </DecoratorBlobContainer>
            </StatisticWrapper>
          );
        })}
      </Content>
    </Container>
  </ContentWithVerticalPadding>
);

export default Statistics;
