import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as ArrowLeftIcon } from 'assets/images/arrow-left-2-icon.svg';

const SliderControlButtonContainer = styled.div`
  ${tw`z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500`}
    svg {
      ${tw`w-8`}
    }
  }
`;

export const PreviousArrow = ({ className, onClick }) => (
  <SliderControlButtonContainer>
    <button className={className} onClick={onClick}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);
