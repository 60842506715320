import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledOverlay = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-90 flex items-center justify-center`}
`;

const Overlay = ({ children, onClick }) => {
  return <StyledOverlay onClick={onClick}>{children}</StyledOverlay>;
};

export default Overlay;
