import React from 'react';
import { ButtonBase } from './ButtonBase';

export const ButtonSecondary = ({
  onClick,
  disabled = false,
  endIcon = null,
  startIcon = null,
  size = 'sm',
  mobileSize = 'xs',
  isFirst = false,
  isLast = false,
  children,
}) => {
  return (
    <ButtonBase
      variant='outlined'
      color='secondary'
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      mobileSize={mobileSize}
      isFirst={isFirst}
      isLast={isLast}
    >
      {children}
    </ButtonBase>
  );
};
