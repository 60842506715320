import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { initGA } from 'helpers/analytics';
import { CONSENT_COOKIE } from 'helpers/constants';
import Separator from 'components/form-controls/Separator';

const Modal = styled.div`
  ${tw`fixed top-1/2 left-1/2 
  transform -translate-x-1/2 -translate-y-1/2 z-50 max-w-md w-11/12 bg-gray-900 text-white px-8 py-6 rounded-2xl shadow-2xl bg-opacity-85`}
`;

const ButtonWrapper = styled.div`
  ${tw`flex justify-center items-center mt-6 space-x-4`}
`;

const AcceptButton = styled.button`
  ${tw`py-2 px-4 text-white rounded-2xl border `}
`;

const TextWrapper = styled.div`
  ${tw`mb-6 `}
`;

const useCookieConsent = () => {
  const [showModal, setShowModal] = useState(false);

  const acceptCookieHandler = () => {
    initGA();
    Cookies.set(CONSENT_COOKIE, 'true', { expires: 150 });
    setShowModal(false);
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue(CONSENT_COOKIE) === 'true';
    if (!isConsent) {
      setShowModal(true);
    }
  }, []);

  return {
    showModal,
    acceptCookieHandler,
  };
};

const CookieConsent = ({ text }) => {
  const { showModal, acceptCookieHandler } = useCookieConsent();

  if (!showModal) return null;

  return (
    <Modal>
      <TextWrapper>{text}</TextWrapper>
      <Separator width='full' mobileWidth='full' smallPaddingY opacity={0.5} />
      <ButtonWrapper>
        <AcceptButton onClick={acceptCookieHandler}>Разбрах</AcceptButton>
      </ButtonWrapper>
    </Modal>
  );
};

export default CookieConsent;
