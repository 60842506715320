export const inputTypes = {
  text: 'text',
  email: 'email',
  password: 'password',
  number: 'number',
  telephone: 'tel',
  message: 'message',
  checkbox: 'checkbox',
  file: 'file',
  url: 'url',
};
