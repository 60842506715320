import React from 'react';
import styled from 'styled-components';

import ThreeColumnFooter from 'components/footers/ThreeColumnFooter';
//import LinkedInInsightTag from 'components/misc/LinkedInInsightTag';
import { pages } from 'config';

const FooterWrapper = styled.footer`
  flex-shrink: 0;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      {/* <LinkedInInsightTag /> */}
      <ThreeColumnFooter quickLinks={quickLinks} productLinks={productLinks} legalLinks={legalLinks} />
    </FooterWrapper>
  );
};

export default Footer;

const quickLinks = {
  header: 'Бързи връзки',
  list: [
    {
      title: 'Ментори',
      href: pages.portal.mentors.all,
    },
    {
      title: 'Корпоративни Обучения',
      href: pages.portal.trainings.corporate.all,
    },
    {
      title: 'Стани Ментор',
      href: pages.becomeMentor,
    },
  ],
};

const productLinks = {
  header: 'Компания',
  list: [
    {
      title: 'Вход',
      href: pages.login,
    },
    {
      title: 'За Нас',
      href: pages.aboutUs,
    },
    {
      title: 'Контакти',
      href: pages.contactUs,
    },
  ],
};

const legalLinks = {
  header: 'Правни връзки',
  list: [
    {
      title: 'Политика на поверителност',
      href: pages.portal.gdpr,
    },
    {
      title: 'Условия на ползване',
      href: pages.portal.termsOfService,
    },
  ],
};
