import React from 'react';
import tw from 'twin.macro';
import { Helmet } from 'react-helmet-async';

import { isDevelopment, TypeErrors } from 'helpers';
import { SectionHeading } from 'components/misc/Headings';
import { ReactComponent as InternalServerSvg } from 'assets/images/internal-server-error-500.svg';
import { ReactComponent as NotFoundSvg } from 'assets/images/404-not-found.svg';

const Container = tw.div`xs:mt-20 lg:mt-0 flex flex-col justify-center items-center h-full p-8`;
const ErrorMessage = tw.pre`text-red-600 mt-4 text-center`;
const Heading = tw(SectionHeading)`font-black text-center leading-tight text-gray-700`;
const ErrorContainer = tw.div`flex justify-center w-full lg:text-lg text-gray-700`;
const StyledSvg = tw.div`xs:h-full md:h-1/2 w-96 md:w-160 lg:w-192`;

const getImage = (type) => {
  switch (type) {
    case TypeErrors.INTERNAL:
      return <StyledSvg as={InternalServerSvg} />;
    case TypeErrors.NOT_FOUND:
      return <StyledSvg as={NotFoundSvg} />;
    default:
      return <StyledSvg as={InternalServerSvg} />;
  }
};

const ErrorFallback = ({
  error,
  heading = 'Опа! Нещо се обърка...',
  text = 'Опитайте да презаредите страницата!',
  type = TypeErrors.INTERNAL,
}) => {
  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Container>
        {getImage(type)}
        <Heading>{heading}</Heading>
        <ErrorContainer>{isDevelopment ? <ErrorMessage>{error.message}</ErrorMessage> : <p>{text}</p>}</ErrorContainer>
      </Container>
    </>
  );
};

export default ErrorFallback;
