import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './main/App';
import { store } from 'store/index';

Modal.setAppElement('#root');

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <StrictMode>
        <App />
      </StrictMode>
    </GoogleOAuthProvider>
  </Provider>
);
