import React from 'react';
import tw from 'twin.macro';

import { ReactComponent as CheckboxIcon } from 'feather-icons/dist/icons/check-circle.svg';

const Container = tw.li`flex items-center`;
const BulletCheckMarkIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const CheckMarkText = tw.h2`ml-2 font-medium text-gray-700`;

const BulletCheckMark = ({ text }) => (
  <Container>
    <BulletCheckMarkIcon />
    <CheckMarkText>{text}</CheckMarkText>
  </Container>
);

export default BulletCheckMark;
