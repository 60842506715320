import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import ReactModalAdapter from 'components/_shared/ReactModalAdapter';
import Overlay from 'components/form-controls/Overlay';

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-100`}
  }
  &.mainHeroModal__content {
    ${tw`mx-auto my-auto max-w-screen-md absolute h-1/2 3xl:h-1/2.5 inset-0 flex justify-center items-center rounded-xl bg-gray-200 outline-none`}
  }
  .content {
    ${({ size }) => tw`${getSize(size)}`}
  }
`;

const getSize = (size) => {
  switch (size) {
    case 'small':
      return 'w-1/2';
    case 'medium':
      return 'w-3/4';
    case 'large':
      return 'w-full';
  }
};

const VideoModal = ({ children, isOpen, onClose, size = 'small' }) => {
  return (
    <>
      {isOpen && <Overlay onClick={onClose} />}
      <StyledModal className='mainHeroModal' size={size} isOpen={isOpen} onRequestClose={onClose}>
        {children}
      </StyledModal>
    </>
  );
};

export default VideoModal;
