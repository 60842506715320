//Note:
// Be extremely careful with changing the values as they are directly registered inside the form.
// If the form is not submitting without throwing an error, check these values.
export const formNamesEnum = {
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  firstname: 'firstname',
  lastname: 'lastname',
  phoneNumber: 'phoneNumber',
  companyName: 'companyName',
  bulstat: 'bulstat',
  message: 'message',
  title: 'title',
  shortDescription: 'shortDescription',
  longDescription: 'longDescription',
  pricePerHour: 'pricePerHour',
  experienceYears: 'experienceYears',
  linkedIn: 'linkedIn',
  gitHub: 'gitHub',
  facebook: 'facebook',
  isVerified: 'isVerified',
  iban: 'iban',
  commissionPercentConsultation: 'commissionPercentConsultation',
  commissionPercentCourse: 'commissionPercentCourse',
  currentBalance: 'currentBalance',
  avatar: 'avatar',
  telephone: 'telephone',
  name: 'name',
  birthDateUtc: 'birthDateUtc',
  country: 'country',
  city: 'city',
  address: 'address',
  postalCode: 'postalCode',
  mentorTermsAndConditions: 'mentorTermsAndConditions',
};
