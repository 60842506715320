import { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'twin.macro';

const GlobalStyles = createGlobalStyle(
  globalStyles,
  `
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(97, 21, 249, 0.4);
      border-radius: 4px;
      transition: background-color 0.3s ease;
      box-shadow: 0 0 2px 1px rgba(97, 21, 249, 0.2);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(97, 21, 249, 0.5);
    }

   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
   .PhoneInputCountry {
     padding-top: 15px;
   }
`
);

export default GlobalStyles;
