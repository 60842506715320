import tw, { styled } from 'twin.macro';

const Separator = ({
  mobileOnly = false,
  width = '4/5',
  mobileWidth = '4/5',
  onlyLine = false,
  smallPaddingY = false,
  opacity = 1,
}) => {
  const widths = {
    '1/5': tw`w-1/5`,
    '2/5': tw`w-2/5`,
    '3/5': tw`w-3/5`,
    '4/5': tw`w-4/5`,
    full: tw`w-full`,
  };

  const customOpacityStyle = `opacity: ${opacity};`;

  const commonStyles = onlyLine
    ? tw`border-t shadow-xl mx-auto`
    : smallPaddingY
    ? tw`border-t shadow-xl my-2 mx-auto`
    : tw`border-t shadow-xl my-6 mx-auto md:my-8`;

  const StyledSeparator = styled.div`
    ${commonStyles}
    ${widths[width]}
    ${customOpacityStyle}
    
    @media (max-width: 768px) {
      ${widths[mobileWidth]}
    }
  `;

  const MobileSeparator = styled.div`
    ${commonStyles}
    ${widths[mobileWidth]}
    ${tw`md:hidden`}
    ${customOpacityStyle}
  `;

  return mobileOnly ? <MobileSeparator /> : <StyledSeparator />;
};

export default Separator;
