import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow-right-2-icon.svg';

const SliderControlButtonContainer = styled.div`
  ${tw`z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500`}
    svg {
      ${tw`w-8`}
    }
  }
`;

export const NextArrow = ({ className, onClick }) => (
  <SliderControlButtonContainer>
    <button className={className} onClick={onClick}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
